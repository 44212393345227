<template>
  <v-container>
    <v-overlay absolute>
      <div
        class="accent pa-6 headline rounded"
        v-text="message"
      />
    </v-overlay>
  </v-container>
</template>

<script>
export default {
  name: 'FinishedContainer',
  props: {
    game: {
      type: Object,
      required: true,
    },
    playerId: {
      type: String,
      required: true,
    },
  },
  computed: {
    currentPlayer () {
      return this.game.playerList.find(player => player.id === this.playerId)
    },
    playerResult () {
      return this.currentPlayer[this.game.type]
    },
    message () {
      const { timePlayed, numberOfMistakes } = this.playerResult
      const timeMessage = this.$tc('multitask.finished.timePlayed', timePlayed, { timePlayed })
      const mistakesMessage = this.$tc('multitask.finished.numberOfMistakes', numberOfMistakes, { numberOfMistakes })
      return `${timeMessage}, ${mistakesMessage}.`
    },
  },
}
</script>
